import { getRequest, patchRequest, postRequest } from './helpers';
import {
  InvestmentProp,
  CreateInvestment,
  WithdrawProp,
} from '@/types/Investment';

export function getInvestments(page = 1): Promise<any> {
  return getRequest({
    url: 'investments',
    params: {
      page,
    },
  });
}

export const getTenures = (): Promise<any> => {
  return getRequest({
    url: 'tenures/list',
  });
};

export const getBanks = (): Promise<any> => {
  return getRequest({
    url: 'banks',
  });
};

export function getInvestment(investmentId: string): Promise<any> {
  return getRequest<InvestmentProp>({
    url: `investments/${investmentId}`,
  });
}

export const createInvestment = (data: CreateInvestment): Promise<any> => {
  return postRequest({
    url: 'investments',
    data: { ...data },
  });
};

export const getTransactions = (page = 1): Promise<any> => {
  return getRequest({
    url: 'investments/transactions',
    params: {
      page,
    },
  });
};

export const getInvestmentTransaction = (
  page = 1,
  investmentId: string,
): Promise<any> => {
  return getRequest({
    url: `investments/${investmentId}/transactions`,
    params: {
      page,
    },
  });
};

export const withdrawInvestment = (
  investmentId: string,
  data: WithdrawProp,
): Promise<any> => {
  return patchRequest({
    url: `investments/${investmentId}`,
    data: { ...data },
  });
};

export const liquidateInvestment = (investmentId: string) => {
  return postRequest({
    url: `investments/${investmentId}`,
  });
};
