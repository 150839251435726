import { defineAsyncComponent, App } from 'vue';
//import * as Icons from './icons';
const components: any[] = [
  //alert
  {
    name: 'alert',
    path: defineAsyncComponent(() => import('./Alert.vue')),
  },

  {
    name: 'form-input',
    path: defineAsyncComponent(() => import('./form/Input.vue')),
  },
  {
    name: 'form-select',
    path: defineAsyncComponent(() => import('./form/Select.vue')),
  },
  {
    name: 'form-currency',
    path: defineAsyncComponent(() => import('./form/Currency.vue')),
  },
  {
    name: 'checkbox',
    path: defineAsyncComponent(() => import('./form/Checkbox.vue')),
  },
  {
    name: 'tab',
    path: defineAsyncComponent(() => import('./form/Tab.vue')),
  },
  //modals
  {
    name: 'modal',
    path: defineAsyncComponent(() => import('./modals/Modal.vue')),
  },
  {
    name: 'alert-modal',
    path: defineAsyncComponent(() => import('./modals/AlertModal.vue')),
  },

  //icons
  {
    name: 'icon-star',
    path: defineAsyncComponent(() => import('./icons/Star.vue')),
  },
  {
    name: 'icon-back',
    path: defineAsyncComponent(() => import('./icons/Back.vue')),
  },
  {
    name: 'withdraw-icon',
    path: defineAsyncComponent(() => import('./icons/WithdrawInfo.vue')),
  },
  {
    name: 'logo',
    path: defineAsyncComponent(() => import('./icons/Logo.vue')),
  },

  //Account
  {
    name: 'account-verification',
    path: defineAsyncComponent(
      () => import('./account/verification/Index.vue'),
    ),
  },

  //Investment
  {
    name: 'investment',
    path: defineAsyncComponent(() => import('./investment/Investment.vue')),
  },
  {
    name: 'transaction',
    path: defineAsyncComponent(() => import('./investment/Transaction.vue')),
  },
  //loaders
  {
    name: 'skeleton-loader',
    path: defineAsyncComponent(() => import('./loaders/SkeletonLoader.vue')),
  },
  {
    name: 'loader',
    path: defineAsyncComponent(() => import('./loaders/Loader.vue')),
  },
];

export default {
  install(app: App): void {
    // Object.keys(Icons).forEach((key: string) =>
    //   app.component(key, (Icons as { [key: string]: ComponentOptions })[key]),
    // );

    components.map((component: any) =>
      app.component(component.name, component.path),
    );
  },
};
