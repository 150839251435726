import { MutationTree, ActionTree } from 'vuex';
import { InvestmentProp } from '@/types/Investment';

const state = {
  investments: [] as InvestmentProp[],
};

const mutations: MutationTree<any> = {
  investments: (state: any, payload: InvestmentProp[]) =>
    (state.investments = payload),
};

const actions: ActionTree<any, any> = {
  setInvestment: ({ commit }: any, payload: InvestmentProp[]) =>
    commit('investments', payload),
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
