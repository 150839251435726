const path = (root: string, route: string): string => `${root}${route}`;

const ROOTS = {
  root: '/',
};

export const APP_PAGES = {
  dashboard: path(ROOTS.root, 'dashboard'),
  account: path(ROOTS.root, 'account'),
  profile: path(ROOTS.root, 'profile'),
  investment: path(ROOTS.root, 'investment'),
  transactions: path(ROOTS.root, 'transaction'),
  investment_details: path(ROOTS.root, 'investment/:id'),
  change_password: path(ROOTS.root, 'change-password'),
};
