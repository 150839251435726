import { RouteRecordRaw } from 'vue-router';
import dashboardRoutes from './dashboardRoutes';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import store from '@/store';

const isLoggedIn = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (store.state.isLoggedIn) next({ name: 'Dashboard' });
  else next();
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/auth/layout.vue'),
    beforeEnter: [isLoggedIn],
  },
  {
    path: '/password/reset/:token',
    name: 'Reset-Password',
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ '../views/auth/reset-password.vue'
      ),
  },
  {
    path: '/verify-mobile',
    name: 'Mobile-Verification',
    component: () =>
      import(
        /* webpackChunkName: "verify-mobile" */ '../views/auth/verify-mobile.vue'
      ),
  },
  dashboardRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () =>
      import(
        /* webpackChunkName: "404-not-found" */ '../views/errors/404-error.vue'
      ),
  },
];

export default routes;
