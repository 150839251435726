import { getRequest, postRequest, patchRequest } from './helpers';
import { User } from '@/types/User';

export const loginRequest = async (
  email: string,
  password: string,
): Promise<any> => {
  return await postRequest<{ token: string; user: User }>({
    url: 'auth/login',
    data: {
      email,
      password,
    },
  });
};

export const profileRequest = async (): Promise<any> => {
  return await getRequest<User>({
    url: 'self',
  });
};

export const registerRequest = async (
  mobile: string,
  email: string,
  password: string,
  first_name: string,
  last_name: string,
): Promise<any> => {
  return await postRequest<{ token: string; user: User }>({
    url: 'auth/register',
    data: {
      mobile,
      email,
      password,
      first_name,
      last_name,
    },
  });
};

export const resendMobileVerification = async (data: any): Promise<any> => {
  return await postRequest({
    url: 'verification/mobile/resend',
    data: { ...data },
  });
};

export const verifyMobileOtp = async (
  otpCode: string | number,
): Promise<any> => {
  return await postRequest({
    url: `verification/mobile/verify/${otpCode}`,
  });
};

export const requestPassword = async (email: string): Promise<any> => {
  return await postRequest({
    url: 'auth/password/request',
    data: { email },
  });
};

export const resetPassword = async (
  email: string,
  token: string,
  password: string,
): Promise<any> => {
  return await patchRequest({
    url: 'auth/password/reset',
    data: { email, token, password },
  });
};
