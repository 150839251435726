import { useStore } from 'vuex';
import { computed } from 'vue';
import { profileRequest, notifications } from '../requests';
import { useRouter } from 'vue-router';

export default function profile() {
  const { state, dispatch } = useStore();
  const { push } = useRouter();

  const user = computed(() => state?.user);

  const firstName = computed(() => user.value?.first_name);

  const loadSession = (): void => {
    if (state.isLoggedIn) {
      profileRequest()
        .then(({ data }) => {
          dispatch('updateUser', data);
        })
        .catch(() => {
          dispatch('logout');
          push('/');
        });
    }
  };

  const getNotifications = (): void => {
    if (state.isLoggedIn) {
      notifications()
        .then((response) =>
          dispatch('utils/setNotifications', response?.data?.data),
        )
        .catch((error) => console.log(error));
    }
  };

  const genderOptions = computed(() => {
    return [
      { title: 'Male', value: 'male' },
      { title: 'Female', value: 'female' },
    ];
  });

  const relationshipOptions = computed(() => {
    return [
      { title: 'Mother', value: 'mother' },
      { title: 'Sister', value: 'sister' },
      { title: 'Brother', value: 'brother' },
      { title: 'Family', value: 'family' },
      { title: 'Nephew', value: 'nephew' },
      { title: 'Other', value: 'other' },
    ];
  });

  const mapProfileData = (form: any = null) => {
    for (const key in form.data) {
      form.data[key].value =
        user?.value?.profile?.[key] ||
        user.value?.[key] ||
        user.value?.bank_accounts?.[0][key] ||
        '';
    }
  };

  const validateProfile = (step: string): boolean => {
    switch (step) {
      case 'profile':
        return (
          user?.value?.profile?.gender &&
          user?.value?.profile?.dob &&
          user?.value?.profile?.address
        );
      case 'mobile':
        return user?.value?.mobile && user?.value?.mobile_verified_at;
      case 'bvn':
        return user.value?.profile?.bvn;
      case 'bank-information':
        return user.value?.bank_accounts.length > 0;
      case 'next-of-kin':
        return (
          user.value?.profile?.next_of_kin_name &&
          user.value?.profile?.next_of_kin_email &&
          user.value?.profile?.next_of_kin_gender &&
          user.value?.profile?.next_of_kin_mobile &&
          user.value?.profile?.next_of_kin_relation
        );
      default:
        return false;
    }
  };

  const isProfileCompleted = computed(() => {
    return (
      !!validateProfile('profile') &&
      !!validateProfile('mobile') &&
      !!validateProfile('bank-information') &&
      !!validateProfile('next-of-kin')
    );
  });

  const determineStep = computed(() => {
    if (!validateProfile('profile') && !validateProfile('bank-information')) {
      return 'personal-information';
    } else if (!validateProfile('next-of-kin')) {
      return 'next-of-kin';
    } else {
      return 'personal-information';
    }
  });

  const profilePercentage = computed(() => {
    let percentage;

    if (validateProfile('profile')) {
      percentage = '25%';
    } else if (validateProfile('profile') && validateProfile('mobile')) {
      percentage = '50%';
    } else if (
      validateProfile('profile') &&
      validateProfile('mobile') &&
      validateProfile('bank-information')
    ) {
      percentage = '75%';
    } else {
      percentage = '10%';
    }
    return percentage;
  });

  return {
    firstName,
    loadSession,
    getNotifications,
    validateProfile,
    determineStep,
    profilePercentage,
    user,
    isProfileCompleted,
    mapProfileData,
    genderOptions,
    relationshipOptions,
  };
}
