import Axios, { AxiosRequestConfig } from 'axios';
import { AUTH_TOKEN } from '@/constants';

export function request<T = unknown>(
  data: AxiosRequestConfig,
): Promise<{ data?: T; message: string; success: boolean }> {
  return Axios.request({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem(AUTH_TOKEN)}`,
    },
    ...data,
  }).then((response) => response.data);
}

export function getRequest<T = any>(options: AxiosRequestConfig) {
  return request<T>({
    method: 'GET',
    params: {
      ...options.params,
      ...options.data, // since get can't parse data
    },
    ...options,
  });
}

export function postRequest<T = unknown>(options: AxiosRequestConfig) {
  return request<T>({
    method: 'POST',
    ...options,
  });
}

export function patchRequest<T = unknown>(options: AxiosRequestConfig) {
  return request<T>({
    method: 'PATCH',
    ...options,
  });
}
