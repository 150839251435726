import { createStore, useStore as useVuexStore, Store } from 'vuex';
import { InjectionKey } from 'vue';
import { User } from '@/types/User';
import { State } from '@/types/State';
import utils from './utils';
import investment from './investment';

export const key = 'store' as unknown as InjectionKey<Store<any>>;

export const useStore = (): Store<any> => useVuexStore(key);

const state: State = {
  isLoggedIn: false,
  user: null,
  token: null,
  tokenName: 'invest-token',
};

export default createStore({
  state: state,
  mutations: {
    authenticate: (state: State, { token, user }): void => {
      state.isLoggedIn = true;
      state.token = token;
      state.user = user;
    },
    updateUser: (state: State, user: User): void => {
      state.user = {
        ...state.user,
        ...user,
      };
    },
    restoreState: (state: State, { token, user }): void => {
      state.token = token;
      state.user = user;

      if (token && user) {
        state.isLoggedIn = true;
      }
    },
    removeUser: (state: State): void => {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
    },
  },
  actions: {
    login: ({ commit, dispatch }, { user, token }: State): void => {
      commit('authenticate', { user, token });
      dispatch('saveUser');
    },
    updateUser: ({ commit, dispatch }, user: User): void => {
      commit('updateUser', user);
      dispatch('saveUser');
    },
    saveUser: ({ state }): void => {
      const newState = {
        isLoggedIn: state.isLoggedIn,
        token: state.token,
        user: state.user,
      };
      localStorage.setItem(state.tokenName, btoa(JSON.stringify(newState)));
    },
    restoreSession: ({ state, commit }): void => {
      const data = localStorage.getItem(state.tokenName);
      data && commit('restoreState', JSON.parse(atob(data)));
    },
    logout: ({ state, commit }): void => {
      commit('removeUser');
      localStorage.removeItem(state.tokenName);
    },
  },
  modules: {
    utils: utils,
    investment: investment,
  },
});
