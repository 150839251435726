import { NextOfKinProp, ProfileProp } from '@/types/User';
import { patchRequest, getRequest, postRequest } from './helpers';
import { AccountProp } from '@/types/Account';

export function changePasswordRequest(
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string,
): Promise<unknown> {
  return patchRequest({
    url: '/profile/password',
    data: {
      former_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation,
    },
  });
}

export const updateProfile = (data: ProfileProp): Promise<any> => {
  return postRequest({
    url: 'profile',
    data: { ...data },
  });
};

export const notifications = (): Promise<any> => {
  return getRequest({
    url: 'notifications',
  });
};

export const markAsRead = (notificationId: string): Promise<any> => {
  return patchRequest({
    url: `notifications/${notificationId}`,
  });
};

export const addBankDetails = (data: AccountProp): Promise<any> => {
  return postRequest({
    url: 'bank-account',
    data: { ...data },
  });
};

export const getBankAccounts = (): Promise<any> => {
  return getRequest({
    url: 'bank-account',
  });
};

export const updateNextOfKin = (data: NextOfKinProp): Promise<any> => {
  return patchRequest({
    url: 'profile/next-of-kin',
    data: { ...data },
  });
};

export const updateDetails = ({ ...data }): Promise<any> => {
  return patchRequest({
    url: 'profile/details',
    data: data,
  });
};
