import { APP_PAGES } from './paths';
import store from '@/store';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

const isAuthenticated = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!store.state?.isLoggedIn) next({ name: 'Login' });
  else next();
};

const isMobileVerified = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!store.state?.user?.mobile_verified_at)
    next({ name: 'Mobile-Verification' });
  else next();
};

const dashboardRoutes = {
  path: APP_PAGES.dashboard,
  name: 'Layout',
  component: () =>
    import(/* webpackChunkName: "dashboard" */ '../views/app/layout.vue'),
  children: [
    {
      path: APP_PAGES.dashboard,
      name: 'Dashboard',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '../views/app/dashboard/index.vue'
        ),
      beforeEnter: [isAuthenticated, isMobileVerified],
    },
    {
      path: APP_PAGES.investment,
      name: 'Investment',
      component: () =>
        import(
          /* webpackChunkName: "investment" */ '../views/app/investment/index.vue'
        ),
      beforeEnter: [isAuthenticated, isMobileVerified],
    },
    {
      path: APP_PAGES.investment_details,
      name: 'Investment-Details',
      component: () =>
        import(
          /* webpackChunkName: "investment-details" */ '../views/app/investment/investment-details.vue'
        ),
      beforeEnter: [isAuthenticated],
    },
    {
      path: APP_PAGES.account,
      name: 'Account',
      component: () =>
        import(
          /* webpackChunkName: "account" */ '../views/app/accounts/index.vue'
        ),
      beforeEnter: [isAuthenticated, isMobileVerified],
    },
    {
      path: APP_PAGES.transactions,
      name: 'Transactions',
      component: () =>
        import(
          /* webpackChunkName: "transactions" */ '../views/app/dashboard/transactions.vue'
        ),
      beforeEnter: [isAuthenticated, isMobileVerified],
    },
    {
      path: APP_PAGES.profile,
      name: 'Profile',
      component: () =>
        import(
          /* webpackChunkName: "profile" */ '../views/app/profile/index.vue'
        ),
      beforeEnter: [isAuthenticated],
    },
    {
      path: APP_PAGES.change_password,
      name: 'ChangePassword',
      component: () =>
        import(
          /* webpackChunkName: "change-password" */ '../views/app/settings/change-password.vue'
        ),
      beforeEnter: [isAuthenticated],
    },
  ],
};

export default dashboardRoutes;
