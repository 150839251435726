import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/scss/app.scss';
import components from '@/components';
import filters from '@/helpers/filters';

const app = createApp(App);

app.config.globalProperties.productionTip = false;

app.config.globalProperties.$filters = filters;

app.use(store).use(router).use(components).mount('#app');
