import { MutationTree, ActionTree, Commit } from 'vuex';
import { TenureProp } from '@/types/Investment';
import { UtilState } from '@/types/State';

const state: UtilState = {
  isSidebarOpen: false,
  tenures: [] as TenureProp[],
  notifications: [],
  loading: false,
  banks: [],
  modals: [],
  modalToken: 'closed-modals',
};

const mutations: MutationTree<UtilState> = {
  toggleSidebar: (state: UtilState): boolean =>
    (state.isSidebarOpen = !state.isSidebarOpen),
  closeSidebar: (state: UtilState): boolean => (state.isSidebarOpen = false),
  setTenures: (state: any, payload: TenureProp): TenureProp =>
    (state.tenures = payload),
  setLoading: (state: UtilState, payload: boolean): boolean =>
    (state.loading = payload),
  notifications: (state: UtilState, payload: any) =>
    (state.notifications = payload),
  banks: (state: UtilState, payload: any) => (state.banks = payload),
  modal: (state: UtilState, payload: string) => state.modals?.push(payload),
};

const actions: ActionTree<UtilState, any> = {
  setTenures: ({ commit }: { commit: Commit }, payload: TenureProp[]): void =>
    commit('setTenures', payload),
  setNotifications: ({ commit }: { commit: Commit }, payload: any): void =>
    commit('notifications', payload),
  setBanks: ({ commit }: { commit: Commit }, payload: any): void =>
    commit('banks', payload),
  setModal: (
    { commit, state }: { commit: Commit; state: UtilState },
    payload: string,
  ) => {
    commit('modal', payload);
    window.localStorage.setItem(state.modalToken, JSON.stringify(state.modals));
  },
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
