const filters: any = {
  formatDate: (date: string): string => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const dateObj = new Date(date);
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day} ${month}, ${year}`;
  },

  capitalize: (str: string): string => str.toUpperCase(),

  isEmpty: (str: string): boolean => !str.trim().length,

  firstLetter: (value: string): string => value[0].toUpperCase(),

  percentage: (value: any) => value.toFixed(2),

  isNullable: (value: any): boolean => {
    return value === null || value === '' || value === undefined;
  },

  truncate: (string: string, length = 10) => {
    if (!string) {
      return string;
    }
    if (string.toString().length > length) {
      return `${string.toString().substring(0, length)}*****`;
    }
    return string;
  },

  dateFormat: (date: string) =>
    typeof date !== 'string' ? date : new Date(date),

  year: () => new Date().getFullYear(),

  sentenceCase: (string: string): string =>
    string?.replace(/(^(\w)|\s(\w))/g, (x) => x.toUpperCase()),

  formatAmount: (x = 0): string =>
    `₦${Number(x)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,

  reference: (): string => {
    let string = '';
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 32; i++) {
      string += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return string;
  },
};

export default filters;
