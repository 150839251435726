import { createRouter, createWebHistory } from 'vue-router';
import routes from './route';
import store from '@/store';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'active',
  routes,
});

router.afterEach(() => {
  store.commit('utils/closeSidebar');
});

export default router;
