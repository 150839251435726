<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted } from 'vue';
import { useStore } from '@/store';
import sessionModule from '@/modules/auth/sessionModule';

export default defineComponent({
  setup: () => {
    const { dispatch } = useStore();

    const { loadSession, getNotifications } = sessionModule();

    onBeforeMount(() => dispatch('restoreSession'));

    onMounted(() => {
      loadSession();
      getNotifications();
    });
  },
});
</script>
